import React, { createContext } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import App from "./App";
import { Toaster } from "react-hot-toast";
import { io } from "socket.io-client";

const container = document.getElementById("root");
const root = createRoot(container);


root.render(
  // <React.StrictMode>
  <>
    <Toaster />
    <ToastContainer />
    <Provider store={store}>
      <App />
    </Provider>

  </>
    
  // </React.StrictMode>
);
