import { FaLink } from "react-icons/fa";
import MapContainer from "../GoogleMap/GoogleMap";
import { getBaseUrl } from "../../config/GetUrl";

// import styles bundle
import 'swiper/css/bundle';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination , Autoplay } from "swiper/modules";



const ListingPageClient = ({userDetail})  => {
    const images = [
        "https://via.placeholder.com/600x400?text=Image+1",
        "https://via.placeholder.com/600x400?text=Image+2",
        "https://via.placeholder.com/600x400?text=Image+3",,
      ];
      

    return (
      <div class="w-100 h-100 rounded-lg">
        <div class="w-100 h-10 rounded-lg d-flex justify-content-center align-items-center">
            <h1 className="fw-bold"> Buisness Details</h1>
        </div>
        <div className="w-100 d-flex mb-3" style={{height: "400px" , paddingLeft:"20px" , paddingRight:"20px" , gap:"20px" , marginTop:"10px"}}>
        <div class="w-50 h-100 overflow-hidden mr-3" style={{borderRadius:"15px"}}>
            <img 
                src={`${getBaseUrl.BASE_URL}${userDetail?.user?.buisnessProfile?.banner[0]}`} 
                alt="img" 
                // style={{width:"100%" , height:"100%"}}
                className="object-fit-cover rounded-lg"
            />
        </div>
            <div className="w-50 d-flex flex-column ms-3">
                <div className="w-100">
                    <h4 className="fw-bold ">Gallery</h4>
                    <div className="mb-3" style={{display:"flex" , gap:"20px" , flexWrap:"wrap" }}>
                      {images?.map((item) => (
                     <div className="" style={{width:"80px" , height:"80px" , borderRadius:"15px"}}>
                      <img src={`${item}`} alt="img" style={{width:"100%" , height:"100%" , borderRadius:"15px"}} />
                    </div> 
                      ))}
                    </div>

                </div>
                <div className="w-100">
                    <h4 className="fw-bold">phone number</h4>
                    <p style={{color:"blue"}}>{userDetail?.user?.buisnessProfile?.phone}</p>
                </div>
                <div className="w-100">
                    <h4 className="fw-bold">Address</h4>
                    <p style={{color:"blue"}}>{userDetail?.user?.buisnessProfile?.address}</p>
                </div>
                <div className="w-100">
                    <h4 className="fw-bold">Website</h4>
                    <p style={{color:"blue"}}>{userDetail?.user?.buisnessProfile?.url}</p>
                </div>
                <div className="w-100">
                    <h4 className="fw-bold">Social Media Links</h4>
                    <a href="https://facebook.com" target="_blank"><FaLink/></a>
                </div>
            </div>
        </div>
        <div className="w-100" style={{paddingLeft:"20px" , paddingRight:"20px"}}>
            <div className="mb-3">
                <h2 className="fw-bold" style={{color:"blue"}}>{userDetail?.user?.buisnessProfile?.name}</h2>
                <p>ratings</p>
            </div>
            <div className="mb-3">
                <p className="fw-bold">description</p>
                <p>{userDetail?.user?.buisnessProfile?.description}</p>
            </div>
        </div>
        <div className="w-100 mb-5" style={{paddingLeft:"20px", paddingRight:"20px"}}>
            <p className="fw-bold">Location</p>
            <div style={{width:"500px" , height:"300px" , backgroundColor:"#f5f5f5" , overflow:"hidden" , borderRadius:"15px"}}>
               <MapContainer long={userDetail?.user?.buisnessProfile?.long} lat={userDetail?.user?.buisnessProfile?.lat}/>
            </div>
        </div>
        <div className="w-100 d-flex gap-3 mb-5" style={{paddingLeft:"20px" , paddingRight:"20px"}}>
           {userDetail?.products?.map((product , index) => (
            <div key={index} style={{width:"200px", height: "300px" , padding:"10px" , borderRadius:"15px" , backgroundColor:"#f5f5f5"}}>
               <div className="w-100 h-50 rounded-lg bg-danger" style={{borderTopLeftRadius: "15px" , borderTopRightRadius:"15px" , marginBottom:"10px"}}>
               <Swiper
                modules={[Autoplay, Pagination]}
                autoplay={{
                  delay: 3000, // Auto-slide every 3 seconds
                  disableOnInteraction: false,
                }}
                pagination={{ clickable: true }}
                loop={true}
                style={{ width: "100%", height: "100%" }}
              >
                {images?.map((image, imgIndex) => (
                  <SwiperSlide key={imgIndex}>
                    <img
                      src={image}
                      alt={`Product ${index} Image ${imgIndex}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
               </div>
               <div className="w-100 h-50 rounded-lg bg-light" style={{borderBottomLeftRadius: "15px" , borderBottomRightRadius:"15px"}}>
                  <h5 className="fw-bold">{product?.name}</h5>
                  <p>$ {product?.price}</p>
               </div>
            </div>
           ))}
        </div>

      </div>
    )
}

export default ListingPageClient;