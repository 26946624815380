// socketService.js
import { io } from "socket.io-client";

let socket = null;

// Function to initialize the socket connection
export const initializeSocketConnection = (url = "http://localhost:3001", options = {}) => {
  if (!socket) {
    socket = io(url, options);

    console.log("Socket initialized");

    socket.on("connect", () => {
      console.log("Connected to the server:", socket.id);
      registerAdmin();
    });

    socket.on("reconnect", () => {
      console.log("Reconnected to the server");
      registerAdmin();
    });

    socket.on("disconnect", () => {
      console.warn("Disconnected from server");
    });
  }
};

// Function to get the socket instance
export const getSocket = () => {
  if (!socket) {
    console.error("Socket not initialized. Call initialize() first.");
  }
  return socket;
};

// Function to emit events
export const emit = (event, data) => {
  if (!socket) {
    console.error("Socket not initialized. Call initialize() first.");
    return;
  }
  socket.emit(event, data);
};

// Function to register admin
const registerAdmin = () => {
    const userId = JSON.parse(localStorage.getItem("user"))._id;
    console.log(userId)
  if (userId) {
    emit("registerAdmin", { userId });
    console.log(`registerAdmin event fired with userId: ${userId}`);
  } else {
    console.warn("No userId found in localStorage. registerAdmin event not fired.");
  }
};
