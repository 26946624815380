import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { login } from "../../slices/auth";
import { clearMessage } from "../../slices/message";


const Login = () => {
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);


  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("This field is required!"),
    password: Yup.string().required("This field is required!"),
  });

  const handleLogin = (formValue) => {
    const { email, password } = formValue;
    setLoading(true);

    dispatch(login({ email, password }))
      .unwrap()
      .then(() => {
        setLoading(false);
        console.log('emitting admin-login event')
      })
      .catch((error) => {
        setLoading(false);
        console.log("eror on login", error);
      });
  };

  // if (isLoggedIn) {
  //   return <Navigate to="/home" />;
  // }

  return (
    <>
      <>
        <div className="wrapper">
          <div className="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
            <div className="container-fluid">
              <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                <div className="col mx-auto">
                  <div className="mb-4 text-center">
                    <img
                      src="app/assets/images/logo-img.png"
                      width="180"
                      alt=""
                    />
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <div className="border p-4 rounded">
                        <div className="text-center">
                          <h3 className="">Sign in</h3>
                          {/* <p>
                            Don't have an account yet?{" "}
                            <a href="authentication-signup.html">
                              Sign up here
                            </a>
                          </p> */}
                        </div>
                        {/* <div className="d-grid">
                                        <a className="btn my-4 shadow-sm btn-white" href="javascript:;"> <span className="d-flex justify-content-center align-items-center">
                                        <img className="me-2" src="app/assets/images/icons/search.svg" width="16" alt="Image Description" />

                        <span>Sign in with Google</span>
                                            </span>
                                        </a> <a href="javascript:;" className="btn btn-facebook"><i className="bx bxl-facebook"></i>Sign in with Facebook</a>
                                    </div> */}
                        <div className="login-separater text-center mb-4">
                          {" "}
                          {/* <span>OR SIGN IN WITH EMAIL</span> */}
                          <hr />
                        </div>
                        <div className="form-body">
                          <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleLogin}
                          >
                            {({ errors, touched }) => (
                              <Form className="row g-3">
                                {/* <form className="row g-3"> */}
                                <div className="col-12">
                                  <label
                                    htmlFor="inputEmailAddress"
                                    className="form-label"
                                  >
                                    Email Address
                                  </label>
                                  <Field
                                    name="email"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.email && touched.email
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="username"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                  {/* <input type="email" className="form-control" id="inputEmailAddress" placeholder="Email Address"> */}
                                </div>
                                <div className="col-12">
                                  <label
                                    htmlFor="inputChoosePassword"
                                    className="form-label"
                                  >
                                    Enter Password
                                  </label>
                                  <div
                                    className="input-group"
                                    id="show_hide_password"
                                  >
                                    <Field
                                      name="password"
                                      type={showPassword ? "text" : "password"}
                                      className={
                                        "form-control" +
                                        (errors.password && touched.password
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="password"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                    {/* <input type="password" className="form-control border-end-0" id="inputChoosePassword" value="12345678" placeholder="Enter Password"> */}
                                    <Link
                                      onClick={() =>
                                        setShowPassword((prev) => !prev)
                                      }
                                      className="input-group-text bg-transparent"
                                    >
                                      <i
                                        className={
                                          showPassword
                                            ? "bx bx-show"
                                            : "bx bx-hide"
                                        }
                                      ></i>
                                    </Link>
                                  </div>
                                </div>
                                {/* <div className="col-md-6">
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked>
                                                    <label className="form-check-label" for="flexSwitchCheckChecked">Remember Me</label>
                                                </div>
                                            </div> */}
                                {/* <div className="col-md-6 text-end">
                                  {" "}
                                  <a href="authentication-forgot-password.html">
                                    Forgot Password ?
                                  </a>
                                </div> */}
                                <div className="col-12">
                                  <div className="d-grid">
                                    <button
                                      type="submit"
                                      className="btn btn-primary "
                                      disabled={loading}
                                    >
                                      {loading && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                      )}
                                      <i className="bx bxs-lock-open"></i>Sign
                                      in
                                    </button>
                                    {/* <button type="submit" className="btn btn-primary"><i className="bx bxs-lock-open"></i>Sign in</button> */}
                                  </div>
                                </div>
                                {message && (
                                  <div className="col-12">
                                    <div
                                      className="alert alert-danger"
                                      role="alert"
                                    >
                                      {message}
                                    </div>
                                  </div>
                                )}
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <>
        {/* <div className="col-md-12 login-form">
      <div className="card card-container">
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleLogin}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Field
                  name="email"
                  type="text"
                  className={
                    "form-control" +
                    (errors.email && touched.email ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="username"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">Password</label>
                <Field
                  name="password"
                  type="password"
                  className={
                    "form-control" +
                    (errors.password && touched.password ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  disabled={loading}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Login</span>
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      {message && (
        <div className="form-group">
          <div className="alert alert-danger" role="alert">
            {message}
          </div>
        </div>
      )}
    </div> */}
      </>
    </>
  );
};

export default Login;
