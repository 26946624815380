import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useGetOfficeAddressQuery, useSaveOfficeAddressMutation } from "../../services/UserManageApi";

const EditOfficeAddress = () => {
const {data: address, isLoading:isDataLoading , refetch} = useGetOfficeAddressQuery()
  const [formData, setFormData] = useState({
    phone: "",
    email: "",
    address: "",
  });

  useEffect(() => {
    if(address){
        setFormData({
            phone: address?.data?.phone,
            email: address?.data?.email,
            address: address?.data?.address,
          });
    }
  }, [!isDataLoading])

  const [showModal, setShowModal] = useState(false);
  const [saveOfficeAddress, { isLoading }] = useSaveOfficeAddressMutation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    console.log("Saved data:", formData);
    try {
      const response = await saveOfficeAddress(formData).unwrap(); 
      if (response?.status) {
        toast.success(response?.message || "Office Address Saved Successfully");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message || "An error occurred while saving the address");
    } finally {
      setShowModal(false); 
      refetch()
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Edit Office Address</h2>
      <form>
        <div className="mb-3">
          <label htmlFor="phone" className="form-label">
            Phone
          </label>
          <input
            type="text"
            className="form-control"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="address" className="form-label">
            Address
          </label>
          <textarea
            className="form-control"
            id="address"
            name="address"
            rows="3"
            value={formData.address}
            onChange={handleChange}
          ></textarea>
        </div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => setShowModal(true)} // Open the modal
        >
          Save Changes
        </button>
      </form>

      {/* Modal */}
      {showModal && (
        <div
          className="modal fade show d-block"
          tabIndex="-1"
          role="dialog"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Office Address</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setShowModal(false)} // Close the modal
                ></button>
              </div>
              <div className="modal-body">
                <p><strong>Phone:</strong> {formData.phone}</p>
                <p><strong>Email:</strong> {formData.email}</p>
                <p><strong>Address:</strong> {formData.address}</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)} // Close the modal
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSave} // Save and close the modal
                >
                 {isLoading ? 'saving ...':'save'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditOfficeAddress;
