import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../config/GetUrl";

export const userManageApi = createApi({
    reducerPath: 'userManageApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://gib-api.alcax.com',
      }),
    endpoints: (builder) => ({
        saveUserByAdmin: builder.mutation({
            query: (data) => ({
                url:`/api/v1/admin/save-user`,
                method: 'POST',
                body: data
            })
        }),
        suspendUserByAdmin: builder.mutation({
            query:(data) => ({
                url:`/api/v1/admin/suspend-user`,
                method: 'PATCH',
                body: data
            })
        }),
        reinstateUserByAdmin: builder.mutation({
            query:(data) => ({
                url:`/api/v1/admin/reinstate-user`,
                method: 'PATCH',
                body: data
            })
        }),
        denyReffereByAdmin: builder.mutation({
            query:({id , reason}) => ({
                url:`/api/v1/admin/deny-referrer/${id}`,
                method: 'PATCH',
                body: {reason}
            })
        }),
        getProductsById: builder.query({
            query:(id) => ({
                url:`/api/v1/leads/get-products/${id}`,
                method: 'GET'
            })
        }),
        getTransactionsById: builder.query({
            query:(id) => ({
                url:`/api/v1/admin/get-transactions/${id}`,
                method: 'GET'
            })
        }),
        getLiveModes: builder.query({
            query:() => ({
                url:`/api/v1/admin/get-live-modes`,
                method: 'GET'
            })
        }),
        getSubscriptionsById: builder.query({
            query:(id) => ({
                url:`/api/v1/admin/get-subscriptions/${id}`,
                method: 'GET'
            })
        }),
        saveFaqs: builder.mutation({
            query:(body) => ({
                url:`/api/v1/admin/save-faqs`,
                method: 'POST',
                body
            })
        }),
        saveOfficeAddress: builder.mutation({
            query:(body) => ({
                url:`/api/v1/admin/save-office-address`,
                method: 'POST',
                body
            })
        }),
        getOfficeAddress: builder.query({
            query:() => ({
                url:`/api/v1/admin/get-office-address`,
                method: 'GET'
            })
        })

    })
})

export const {
    useSaveUserByAdminMutation , 
    useSuspendUserByAdminMutation, 
    useReinstateUserByAdminMutation,
    useGetProductsByIdQuery,
    useDenyReffereByAdminMutation,
    useGetTransactionsByIdQuery,
    useGetLiveModesQuery,
    useGetSubscriptionsByIdQuery,
    useSaveFaqsMutation,
    useSaveOfficeAddressMutation,
    useGetOfficeAddressQuery
} = userManageApi
