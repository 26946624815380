import React, { useState, useEffect, useCallback, createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import Login from "./app/components/Auth/login";
import AuthLayout from "./app/components/layouts/auth";
import Home from "./app/components/Dashboard/Home";
import DashBoardLayout from "./app/components/layouts/dashboard";
import Users from "./app/components/Users/users";
import UserView from "./app/components/Users/userView";
import EditUser from "./app/components/Users/userEdit";
// import Register from "./app/components/Register";
// import Home from "./app/components/Home";
// import Profile from "./app/components/Profile";
// import BoardUser from "./app/components/BoardUser";
// import BoardModerator from "./app/components/BoardModerator";
// import BoardAdmin from "./app/components/BoardAdmin";

import { logout } from "./app/slices/auth";
import StaticContent from "./app/components/StaticContent/StaticContent";
import Support from "./app/components/Support";
import Transactions from "./app/components/transactions/Transactions";
import TransactionDetails from "./app/components/transactions/TransactionDetails";
import Withdrawals from "./app/components/withdrawals/Withdrawals";
import AppRoutes from "./app/routes/AppRoutes";
import { initializeSocketConnection } from "./app/config/socketConnection";

initializeSocketConnection();

const App = () => {


  return (

    <AppRoutes />
 
  );
};

export default App;
