import { createSlice } from '@reduxjs/toolkit';

const addUserState = {
  register: {
    email:'',
    password:'',
    name:'',
    role:'',
  },
  businessProfile:{
    name: '',
    banner: [], // To store an array of banner image URLs
    socialLinks: [], // To store an array of social links
    description: '',
    phone: '',
    state: '',
    logo: '', // To store the logo image URL
    lat: '', // Latitude for the business location
    long: '', // Longitude for the business location
    url: '',
    category: '',
    address: ''  
  },
  userId:null
};

const addUserSlice = createSlice({
  name: 'addUser',
  initialState: addUserState,
  reducers: {
    addUserAuth: (state , action) => {
        state.register = action.payload
    },
    addBusinessProfile: (state, action) => {
        const { name, socialLinks, description, phone, state: businessState, url, category, address, lat, long } = action.payload;
      
        state.businessProfile = {
          ...state.businessProfile, // Spread the previous state
          name: name || state.businessProfile.name,
          socialLinks: socialLinks || state.businessProfile.socialLinks,
          description: description || state.businessProfile.description,
          phone: phone || state.businessProfile.phone,
          state: businessState || state.businessProfile.state,
          url: url || state.businessProfile.url,
          category: category || state.businessProfile.category,
          address: address || state.businessProfile.address,
          lat: lat || state.businessProfile.lat,
          long: long || state.businessProfile.long
        };
      },
      setUserId: (state, action) => {
        state.userId = action.payload;
      },
      initialiseUserState: (state, action) => {
        const data = action.payload;
        
        const initialisationData = {
            register: {
                email: data.email ?? '',
                password: data.password ?? '',
                name: data.name ?? '',
                role: data.role ?? '',
            },
            businessProfile: {
                name: data.buisnessProfile?.name ?? '',
                socialLinks: data.buisnessProfile?.socialLinks ?? [],
                description: data.buisnessProfile?.description ?? '',
                phone: data.buisnessProfile?.phone ?? '',
                state: data.buisnessProfile?.state ?? '',
                url: data.buisnessProfile?.url ?? '',
                category: data.buisnessProfile?.category?._id ?? '',
                address: data.buisnessProfile?.address ?? '',
                logo: data.buisnessProfile?.logo ?? '',
                banner: data.buisnessProfile?.banner ?? [],
                lat: data.buisnessProfile?.lat ?? 0,
                long: data.buisnessProfile?.long ?? 0,
            },
            userId: data._id ?? null
        };
    
        console.log("initialisationData : ", initialisationData);
    
        // Always return a new state object, instead of modifying the existing one
        return initialisationData;
    },    
      resetAddUserState: () => addUserState,
  },
});

export const { addUserAuth , addBusinessProfile , setUserId , resetAddUserState , initialiseUserState} = addUserSlice.actions;

export default addUserSlice.reducer;
