import React, { useState, useEffect } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { addUserAuth } from "../../../slices/addUserSlice";
import { useGetUserDetailQuery } from "../../../services/UserApi";

const RegisterUserForm = ({ currentPage, setCurrentPage }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [registerUser, setRegisterUser] = useState({
    email: "",
    password: "",
    name: "",
    role: "",
  });

  const [debouncedRegisterUser, setDebouncedRegisterUser] = useState(registerUser);
  const dispatch = useDispatch();

  const addUserState = useSelector((state) => state.addUser);

  // Sync form fields with addUserState.register
  useEffect(() => {
    if (addUserState?.register) {
      setRegisterUser({
        email: addUserState.register.email || "",
        password: addUserState.register.password || "",
        name: addUserState.register.name || "",
        role: addUserState.register.role || "",
      });
    }
  }, [addUserState.register]);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRegisterUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Debouncing logic
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedRegisterUser(registerUser);
    }, 300); // Adjust the debounce delay as needed

    return () => clearTimeout(timer); // Cleanup the timeout on unmount or input change
  }, [registerUser]);

  const handleNext = () => {
    console.log("Moving to the next page with data:", debouncedRegisterUser);
    dispatch(addUserAuth(debouncedRegisterUser));
    setCurrentPage(1);
  };

  const handleBack = () => {
    setCurrentPage(0);
  };

  return (
    <form className="p-4">
      <h4 className="mb-4">User Information</h4> 

      {/* Name Field */}
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Name
        </label>
        <input
          type="text"
          className="form-control"
          id="name"
          name="name"
          value={registerUser.name}
          onChange={handleInputChange}
          placeholder="Enter full name"
          required
        />
      </div>

      {/* Email Field */}
      <div className="mb-3">
        <label htmlFor="email" className="form-label">
          Email
        </label>
        <input
          type="email"
          className="form-control"
          id="email"
          name="email"
          value={registerUser.email}
          onChange={handleInputChange}
          placeholder="Enter email address"
          required
        />
      </div>

      {/* Password Field */}
      <div className="mb-3 position-relative">
        <label htmlFor="password" className="form-label">
          Password
        </label>
        <div className="input-group">
          <input
            type={showPassword ? "text" : "password"}
            className="form-control"
            id="password"
            name="password"
            value={registerUser.password}
            onChange={handleInputChange}
            placeholder="Enter password"
            required
          />
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={togglePasswordVisibility}
            style={{ zIndex: 0 }}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>
      </div>

      {/* Role Dropdown */}
      <div className="mb-3">
        <label htmlFor="role" className="form-label">
          Role
        </label>
        <select
          className="form-select"
          id="role"
          name="role"
          value={registerUser.role}
          onChange={handleInputChange}
          required
        >
          <option value="" disabled>
            Select role
          </option>
          <option value="Client">Client</option>
          <option value="Referrer">Referrer</option>
        </select>
      </div>

      {/* Back and Next Buttons */}
      <div className="d-flex justify-content-between">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={handleBack}
        >
          Back
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleNext}
        >
          Next
        </button>
      </div>
    </form>
  );
};

export default RegisterUserForm;
