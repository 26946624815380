import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";
import { useDenyReffereByAdminMutation } from "../../services/UserManageApi";
import { toast } from "react-toastify";

const DenyReffererModal = (props) => {
  const { showDenyModal, handleClose, refetch , userId } = props;

  const [denyReffererByAdmin , {isLoading, isError, isSuccess}] = useDenyReffereByAdminMutation();
  
  const [reason, setReason] = useState("");

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const denyRefferer = async() => {
    if (reason.trim() === "") {
      alert("Please provide a reason for denying the referrer.");
      return;
    }
    console.log("Referrer denied for reason:", reason);
    const body = {reason: reason};

    const res = await denyReffererByAdmin({id:userId , reason}).unwrap();
    console.log("res : ", res);
    if(res.status){
        refetch();
        toast.success("Referrer denied successfully");
    }

    // Close the modal after action
    handleClose();
  };

  return (
    <Modal show={showDenyModal} onHide={handleClose} centered>
      <Modal.Header closeButton={false} className="border-0 mt-3 pt-0 pb-2">
        <h5 className="text-center w-100">Deny Referrer</h5>
      </Modal.Header>
      <Modal.Body className="text-center px-md-5 pb-0">
        <h4 className="text-red-sure">
          Please state a reason for denying this referrer!
        </h4>
        <textarea
          value={reason}
          onChange={handleReasonChange}
          placeholder="Enter the reason here..."
          className="form-control mt-3"
          rows={5} // Adjust the number of rows for height
        />
      </Modal.Body>
      <Modal.Footer className="d-flex border-0 justify-content-center px-md-5 w-100 mx-auto mb-3 pb-0">
        <button
          type="button"
          onClick={denyRefferer}
          className="btn w-100 btn-danger py-2 text-white"
        >
          Deny Referrer
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DenyReffererModal;
