import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addBusinessProfile, setUserId } from '../../../slices/addUserSlice';
import { useSaveUserByAdminMutation } from '../../../services/UserManageApi';
import { toast } from 'react-toastify';
import { useGetCategoryQuery } from '../../../services/CategoryApi';

const BusinessProfileDetails = ({ currentPage, setCurrentPage }) => {
  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();
  
  // Accessing addUserState directly
  const addUserState = useSelector(state => state.addUser);

  const [saveUserByAdmin, { isLoading, isError, error }] = useSaveUserByAdminMutation();
  const { data: retrieveCategory, refetch, isLoading: categoryLoading } = useGetCategoryQuery();

  useEffect(() => {
    refetch();
    if (retrieveCategory?.data?.category) {
      setCategories(retrieveCategory.data.category);
    }
  }, []); // Load categories

  // Set form values based on addUserState businessProfile
  useEffect(() => {
    if (addUserState?.businessProfile) {
      setBusinessProfile({
        name: addUserState.businessProfile.name || '',
        socialLinks: addUserState.businessProfile.socialLinks || [],
        description: addUserState.businessProfile.description || '',
        phone: addUserState.businessProfile.phone || '',
        businessState: addUserState.businessProfile.state || '',
        url: addUserState.businessProfile.url || '',
        category: addUserState.businessProfile.category || '',
        address: addUserState.businessProfile.address || '',
        lat: addUserState.businessProfile.lat || '',
        long: addUserState.businessProfile.long|| ''
      });
    }
  }, [addUserState]); // Update whenever addUserState changes

  const [businessProfile, setBusinessProfile] = useState({
    name: '',
    socialLinks: [],
    description: '',
    phone: '',
    businessState: '',
    url: '',
    category: '',
    address: '',
    lat: '',
    long: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {console.log(businessProfile)} , [businessProfile])

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Handle the case for socialLinks (comma separated values)
    if (name === 'socialLinks') {
      // Split the string by commas and trim any extra spaces around the values
      const socialLinksArray = value.split(',').map((link) => link.trim()); // Ensure this is inside the conditional block
      
      // Now update the state for businessProfile and dispatch the change
      setBusinessProfile((prevProfile) => ({
        ...prevProfile,
        [name]: socialLinksArray // Set socialLinks as an array
      }));
  
      // Dispatch the updated profile with the correct socialLinks array
      dispatch(addBusinessProfile({
        ...businessProfile,
        socialLinks: socialLinksArray // Update socialLinks array in the store
      }));
    } else {
      // For all other fields, just update the businessProfile as usual
      setBusinessProfile((prevProfile) => ({
        ...prevProfile,
        [name]: value
      }));
  
      // Dispatch the updated profile for other fields
      dispatch(addBusinessProfile({
        ...businessProfile,
        [name]: value
      }));
    }
  };
  
  
  const handleNext = async () => {
    setIsSubmitting(true);
    try {
      const result = await saveUserByAdmin(addUserState);
      if (result?.data?.status === true) {
        dispatch(setUserId(result?.data?.data?.userId));
        setCurrentPage(2);
      } else {
        toast.error(result?.data?.data?.message || 'Failed to save user');
      }
    } catch (err) {
      toast.error('An error occurred while saving user');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBack = () => {
    setCurrentPage(0);
  };

  return (
    <form className="p-4">
      <h4 className="mb-4">Business Profile Details</h4>

      {/* Name Field */}
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Business Name
        </label>
        <input
          type="text"
          className="form-control"
          id="name"
          name="name"
          value={businessProfile.name}
          onChange={handleChange}
          placeholder="Enter business name"
          required
        />
      </div>

      {/* Description Field */}
      <div className="mb-3">
        <label htmlFor="description" className="form-label">
          Description
        </label>
        <textarea
          className="form-control"
          id="description"
          name="description"
          value={businessProfile.description}
          onChange={handleChange}
          placeholder="Enter business description"
        />
      </div>

      {/* Social Links */}
      <div className="mb-3">
        <label htmlFor="socialLinks" className="form-label">
          Social Links (Comma separated)
        </label>
        <input
          type="text"
          className="form-control"
          id="socialLinks"
          name="socialLinks"
          value={businessProfile.socialLinks}
          onChange={handleChange}
          placeholder="Enter social links"
        />
      </div>

      {/* Phone Field */}
      <div className="mb-3">
        <label htmlFor="phone" className="form-label">
          Phone
        </label>
        <input
          type="text"
          className="form-control"
          id="phone"
          name="phone"
          value={businessProfile.phone}
          onChange={handleChange}
          placeholder="Enter phone number"
          required
        />
      </div>

      {/* State Field */}
      <div className="mb-3">
        <label htmlFor="state" className="form-label">
          State
        </label>
        <input
          type="text"
          className="form-control"
          id="state"
          name="state"
          value={businessProfile.businessState}
          onChange={handleChange}
          placeholder="Enter state"
        />
      </div>

      {/* URL Field */}
      <div className="mb-3">
        <label htmlFor="url" className="form-label">
          URL
        </label>
        <input
          type="url"
          className="form-control"
          id="url"
          name="url"
          value={businessProfile.url}
          onChange={handleChange}
          placeholder="Enter business URL"
        />
      </div>

      {/* Category Dropdown */}
      <div className="mb-3">
        <label htmlFor="category" className="form-label">
          Category
        </label>
        <select
          className="form-select"
          id="category"
          name="category"
          value={businessProfile.category}
          onChange={handleChange}
          required
        >
          <option value="">Select a category</option>
          {categories?.map((item) => (
            <option key={item._id} value={item._id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>

      {/* Address Field */}
      <div className="mb-3">
        <label htmlFor="address" className="form-label">
          Address
        </label>
        <input
          type="text"
          className="form-control"
          id="address"
          name="address"
          value={businessProfile.address}
          onChange={handleChange}
          placeholder="Enter address"
        />
      </div>

      <div className="mb-3">
        <label htmlFor="lat" className="form-label">
          latitude
        </label>
        <input
          type="text"
          className="form-control"
          id="lat"
          name="lat"
          value={businessProfile.lat}
          onChange={handleChange}
          placeholder="Enter latitude"
        />
      </div>
      <div className="mb-3">
        <label htmlFor="long" className="form-label">
          longitude
        </label>
        <input
          type="text"
          className="form-control"
          id="long"
          name="long"
          value={businessProfile.long}
          onChange={handleChange}
          placeholder="Enter longitude"
        />
      </div>

      {/* Back and Next Buttons */}
      <div className="d-flex justify-content-between">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={handleBack}
          disabled={isSubmitting}
        >
          Back
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleNext}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <span className="spinner-border spinner-border-sm mr-1"></span>
          ) : (
            'Next'
          )}
        </button>
      </div>
    </form>
  );
};

export default BusinessProfileDetails;
