import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";
import { useReinstateUserByAdminMutation, useSuspendUserByAdminMutation } from "../../services/UserManageApi";
import { toast } from "react-toastify";


const AccountStatusModal = (props) => {
  const { showModal, handleClose ,userId,  status , refetch} = props;
  const [suspendAccount , {isLoading, isError, isSuccess}] = useSuspendUserByAdminMutation();
  const [reinstateAccount , {isLoading:reinstateLoading}] = useReinstateUserByAdminMutation();

  const confirmClick = async() => {
    try{
    if(status === "Suspend"){
     const res = await suspendAccount({userId:userId , is_account_suspended:true});
     console.log("res : ", res?.data.data);
     if(res?.data?.data?.status){
       toast.success("Account suspended successfully");
     }
    }else if(status === "Reinstate"){
      const res = await reinstateAccount({userId:userId , is_account_suspended:false});
      if(res?.data?.data?.status){
        toast.success("Account reinstated successfully");
      }
    }
    }catch(error){
        console.log(error.message);
    }finally{
        refetch();
        handleClose();
    }

  };
  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header closeButton={false} className="border-0 mt-3 pt-0 pb-2">
        {/* <div className="d-flex justify-content-center w-100">
          sfdksjaf
        </div> */}
      </Modal.Header>
      <Modal.Body className="text-center px-md-5  pb-0">
        <h4 className="text-red-sure">
          {`Are you sure you want to ${status} this user's account?`}
        </h4>
      </Modal.Body>
      <Modal.Footer className="d-flex border-0 justify-content-center  px-md-5 w-100 mx-auto mb-3 pb-0">
        <button
          type="submit"
          onClick={confirmClick}
          className="btn w-100  btn-danger py-2 text-white"
        >
          Yes
        </button>
        <Button
          onClick={handleClose}
          className="bg-none px-md-4"
        >
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AccountStatusModal