import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSaveFaqsMutation } from "../../services/UserManageApi";
import axios from "axios";
import { toast } from "react-toastify";

const FaqEditor = () => {
  const [addedFaqs, setAddedFaqs] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [saveFaqsByAdmin, { isLoading }] = useSaveFaqsMutation();

  const addFaq = () => {
    if (title && description) {
      setAddedFaqs([...addedFaqs, { title, description }]);
      setTitle(""); // Reset the fields
      setDescription("");
    } else {
      alert("Please fill in both title and description!");
    }
  };

  const removeFaq = (index) => {
    setAddedFaqs(addedFaqs.filter((_, i) => i !== index));
  };

  const saveFaqs = async() => {
    console.log("Saving FAQs:", addedFaqs);
    try{
      const response = await saveFaqsByAdmin({faqData:addedFaqs}).unwrap();
    // const response = await axios.post("https://gib-api.alcax.com/api/v1/admin/save-faqs", {faqData:addedFaqs});
    if(response?.status){
        toast.success(response?.data.message);
    }
      console.log("response:", response);
    }catch(error){
     console.log("error:", error);
    }finally{
      setAddedFaqs([]);
      setTitle("");
      setDescription("");
    }
  };

  return (
    <div
      className="container py-4"
      style={{
        height: "500px",
        maxHeight: "500px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        overflow: "hidden",
      }}
    >
      <div className="row h-100">
        {/* Left Section: Add FAQ */}
        <div className="col-md-6 d-flex flex-column justify-content-between">
          <div>
            <h4>Add FAQ</h4>
            <div className="mb-3">
              <label className="form-label">Title:</label>
              <input
                type="text"
                className="form-control"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enter FAQ Title"
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Description:</label>
              <textarea
                className="form-control"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows="4"
                placeholder="Enter FAQ Description"
              />
            </div>
          </div>
          <button
            onClick={addFaq}
            className="btn btn-primary"
            disabled={!title || !description}
          >
            Add FAQ
          </button>
        </div>

        {/* Right Section: Display Added FAQs */}
        <div className="col-md-6 d-flex flex-column">
  <h4>Added FAQs</h4>
  <div
    className="flex-grow-1 overflow-auto mb-3"
    style={{
      border: "1px solid #ddd",
      borderRadius: "8px",
      padding: "8px",
      height: "300px", // Fixed height for the scrollable area
    }}
  >
    {addedFaqs.length > 0 ? (
      <ul className="list-group">
        {addedFaqs.map((faq, index) => (
          <li
            key={index}
            className="list-group-item d-flex justify-content-between align-items-start"
          >
            <div>
              <strong>{faq.title}</strong>
              <p className="mb-0">{faq.description}</p>
            </div>
            <button
              onClick={() => removeFaq(index)}
              className="btn btn-danger btn-sm"
            >
              <FaTimes />
            </button>
          </li>
        ))}
      </ul>
    ) : (
      <p className="text-muted">No FAQs added yet.</p>
    )}
  </div>
  {addedFaqs.length > 0 && (
    <button
      onClick={saveFaqs}
      className="btn btn-success"
      style={{ marginTop: "auto" }} // Ensures the button stays pinned at the bottom
    >
      {isLoading ? "Saving FAQs..." : "Save All FAQs"}
    </button>
  )}
</div>

      </div>
    </div>
  );
};

export default FaqEditor;
