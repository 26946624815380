import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { useGetUserListQuery, useApprovedRefereeMutation, useDeleteUserMutation } from "../../services/UserApi"
import { FaCheck } from 'react-icons/fa';
import { toast } from "react-toastify";
import UserEdit from './userEdit';
import UserView from './userView';
import DeleteUser from './DeleteUser';
import AddUserModal from './addUser';
import { useDispatch } from 'react-redux';
import { resetAddUserState } from '../../slices/addUserSlice';
import EditUserModal from './userEdit';
import { LuPauseOctagon } from "react-icons/lu";
import AccountStatusModal from './AccountStatusModal';
import { useGetLiveModesQuery, useSuspendUserByAdminMutation } from '../../services/UserManageApi';
import { MdOutlineCancel } from "react-icons/md";
import DenyReffererModal from './DenyReffererModal';
import { getSocket } from '../../config/socketConnection';



const Users = () => {

    const { data, refetch } = useGetUserListQuery();
    const [userList, setUserList] = useState([])
    const [paginated, setPaginated] = useState([])
    const [page, setPage] = useState(0)
    const [offset, setOffset] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0)
    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState('all')
    const [approvedReferee] = useApprovedRefereeMutation();
    const [userID, setUserId] = useState('')
    const [showEdit, setShowEdit] = useState(false)
    const [showView, setShowView] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteUser] = useDeleteUserMutation();
    const [showAddUser, setShowAddUser] = useState(false)
    const [showAccountStatusModal, setShowAccountStatusModal] = useState(false)
    const[showAccountStatusModalForReinstate, setShowAccountStatusModalForReinstate] = useState(false)
    const [showDenyReffereModal, setShowDenyReffereModal] = useState(false)
    const [allModes, setAllModes] = useState([])


    const dispatch = useDispatch();

    const {data:livemodes , isError , isLoading} = useGetLiveModesQuery();

    useEffect(() => {
        if (livemodes?.status) {
          setAllModes(livemodes?.data?.livemodes)
          console.log("livemodes", livemodes)
        }
    } , [livemodes])

    useEffect(() => {
        refetch()
    } , [allModes])

    useEffect(() => {
        const socket = getSocket();
        socket.on("new_modes" , (newModes) => {
            // setAllModes(newModes)
            console.log("new modes are available >>>>>>>>> !")
            refetch()
        })
        return () => {
            socket.off("new_modes")
        }
    } , [])

    const updatePaginated = (paginated) => {
        if (allModes?.length > 0) {
            const newPaginated=  paginated?.map((item) => {
                const matchingLiveMode = allModes?.find((liveMode) => liveMode._id === item._id);
                console.log('matchingLiveMode' , matchingLiveMode)
                if (matchingLiveMode) {
                    return {
                        ...item,
                        modes: {
                            demoCount: matchingLiveMode.demoCount,
                            mapCount: matchingLiveMode.mapCount
                        }
                    };
                }

                return item;
            });
            return newPaginated;
    }
    }

   

    const handleSearch = (e) => {
        setSearch(e.target.value)
        setOffset(0)
        setPage(0)

    }

    const handleJobFilter = (e) => {
        setFilter(e.target.value)
        setOffset(0)
        setPage(0)
    }


    const handleAccountSuspend = async(id) => {
       setShowAccountStatusModal(true)
       setUserId(id)
    }

    const handleDenyRefferer = async(id) => {
        setShowDenyReffereModal(true)
        setUserId(id)
    }

    const handlePageClick = (e) => {
        setPage(e.selected)
        const newOffset = (e.selected * itemsPerPage);
        setOffset(newOffset)
    }
    const paginationHandler = () => {
        const endOffset = offset + itemsPerPage;
        let paginated = []
        if (search.length > 0) {
            let searchArr = filter == "all" ? userList?.filter((item) => item?.name?.toLowerCase().includes(search?.toLowerCase()) ||item?.email?.toLowerCase().includes(search?.toLowerCase())) : userList?.filter((item) => ((item?.name?.toLowerCase().includes(search?.toLowerCase()) || item?.email?.toLowerCase().includes(search?.toLowerCase())) && item.role == filter))
            setPageCount(Math.ceil(searchArr?.length / itemsPerPage))
            paginated = searchArr?.slice(offset, endOffset)
        }
        else {
            setPageCount(Math.ceil(userList?.length / itemsPerPage))
            let filtered = filter == "all" ? userList?.filter((item) => item?.name !== null) : userList?.filter((item) => (item?.name !== null) && item.role == filter)
            paginated = filtered?.slice(offset, endOffset)
        }
        let newPaginated = updatePaginated(paginated)
        setPaginated(newPaginated)
    }
    useEffect(() => {
        paginationHandler()
    }, [userList, offset, search, filter]);
    useEffect(() => {
        let userFilter = data?.data?.users.filter((user) => user?.name !== null)
        let reverseObj=userFilter?.reverse() 
        setUserList(reverseObj)

        setPageCount(Math.ceil(reverseObj?.length / itemsPerPage))
    }, [data])
    const handleApproved = (id) => {
        approvedReferee(id)
            .unwrap()
            .then((res) => {
                toast.success(res?.message);
                refetch()
                // handleClose();
                // setShowLoader(false);
            })
            .catch((err) => {
                //  setShowLoader(false);
                toast.error(err?.data?.message);
            });

    }
    const handleViewHandler = (id) => {
        setUserId(id)
        setShowView(true)
    }
    const handleEditHandler = (id) => {
        setShowEdit(true)
        setUserId(id)
    }

    const handleDeleteHandler = (id) => {
        setUserId(id)
        setShowDeleteModal(true)
    }

    const handleClose = () => {
        setShowEdit(false)
        setShowView(false)
        setShowDeleteModal()
        setShowAddUser(false)
        setShowAccountStatusModal(false)
        setShowAccountStatusModalForReinstate(false)
        dispatch(resetAddUserState());
        setShowDenyReffereModal(false)
    }
    const onPropertyEdit = (newData) => {
        if (newData) {
            refetch()
        }
    }

    const handleDeleteUser = () => {
        //  setShowLoader(true)
        deleteUser(userID)
            .unwrap()
            .then((res) => {
                toast.success(res?.message);
                // setShowLoader(false)
                refetch();
            })
            .catch((err) => {
                // setShowLoader(false)
                toast.error(err?.data?.message);
            });
    };

    const handleAddUser = () => {
        setShowAddUser(true)
    }

    const handleAccountReinstate = (id) => {
        setShowAccountStatusModalForReinstate(true)
        setUserId(id)
    }

    return (
        <div className="container">
            <div className="clearfix mb-3">
                <div className="float-start">
                    <header className="jumbotron">
                        <h3 className='mb-0'>User</h3>
                    </header>
                </div>
                {/* <div className="float-end">
                    <button type="button" className="btn btn-success float-end  d-flex align-items-center fw-bolder"
                    > <i className='bx bx-plus'></i>Add User</button>
                </div> */}
            </div>
            {showView && (<UserView showView={showView} handleClose={handleClose} userID={userID} />)}
            {showEdit && <EditUserModal show={showEdit} handleClose={handleClose} userId={userID} onPropertyEdit={onPropertyEdit} refetch={refetch}/>}
            {showDeleteModal && <DeleteUser showDeleteModal={showDeleteModal} handleClose={handleClose} handleDelete={handleDeleteUser} />}
            {showAddUser && <AddUserModal show={showAddUser} handleClose={handleClose} refetch={refetch} />}
            {showAccountStatusModal && <AccountStatusModal showModal={showAccountStatusModal} userId={userID} handleClose={handleClose}  status="Suspend" refetch={refetch} />}
            {showAccountStatusModalForReinstate && <AccountStatusModal showModal={showAccountStatusModalForReinstate} userId={userID} handleClose={handleClose}  status="Reinstate"  refetch={refetch} />}
            {showDenyReffereModal && <DenyReffererModal showDenyModal={showDenyReffereModal} userId={userID} handleClose={handleClose} refetch={refetch} />}
            <div className="card mt-4">
                <div className="card-body">
                    <div className="clearfix mb-3">
                        <div className="float-start">
                            <div class="search-user"><label className="d-flex align-items-center">Search  <input type="search" class="search-input form-control ms-2" placeholder="" aria-controls="table" onChange={handleSearch} /></label></div>
                        </div>
                        {/* <div className='float-end'>
                            <button type="button" className="btn btn-success float-end d-flex align-items-center fw-bolder"
                                onClick={() => {}}> <i className='bx bx-plus'></i>Add
                            </button>                        
                       </div> */}
                        <div className="d-flex align-items-center gap-3 float-end">
                            <div class="search-user"><label className="d-flex align-items-center text-nowrap">Filter
                                <select onChange={handleJobFilter} className="ms-2 form-control form-select">
                                    <option value="all">All</option>
                                    <option value="Client">Client</option>
                                    <option value="Referrer">Referrer</option>
                                    <option value="Leads">Leads</option>
                                </select>
                            </label></div>
                            <button type="button" className="btn btn-success float-end d-flex align-items-center fw-bolder"
                                onClick={handleAddUser}> <i className='bx bx-plus'></i>Add user
                            </button>                        
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table class="table category-table w-100">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Role</th>
                                    <th scope="col">Date Created</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">live modes</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginated?.map((user, index) =>
                                    <tr key={index}>
                                        <th scope="row">{((page + 1) * itemsPerPage - itemsPerPage) + index + 1}</th>
                                        <td>{user.name}</td>
                                        <td>
                                            {user.email}
                                            {/* sdjsgahdgsa */}
                                        </td>
                                        <td>{user.role}</td>
                                        <td>{user.created_at.split('T')[0]}</td>
                                        <td>
                                            {(user.status =='Approved' && user.role == 'Referrer') ? <p style={{ color: "green" }}>{user?.status}</p> : (((user.role == 'Client' ) || (user.role == 'Leads' )) ? <p style={{ color: "orange" }}>Accept</p> : <p style={{ color: "red" }}>{user?.status}</p>)}
                                        </td>
                                        <td>{
                                            user.modes? `${user.modes.demoCount} Demos | ${user.modes.mapCount} Maps` : 'NA'
                                            }
                                        </td>
                                        <td className='d-flex align-items-center'>
                                            <button type="button" class="btn btn-outline-success d-flex align-items-center" onClick={() => handleViewHandler(user?._id)}> <i className='bx bx-show'></i> View</button>
                                            <button type="button" class="btn btn-outline-primary  mx-3 d-flex align-items-center"
                                                onClick={() => handleEditHandler(user?._id)}
                                            > <i className='bx bx-pencil'></i>  Edit</button>
                                            <button type="button" class="btn btn-outline-danger d-flex align-items-center" onClick={() => handleDeleteHandler(user?._id)} ><i className='bx bx-trash'></i> delete</button>
                                            {user.role == 'Referrer' && 
                                            <div style={{display: "flex" , gap:"5px"}}>
                                            <button class="btn btn-outline-success  mx-3 d-flex align-items-center" type="button" onClick={() => handleApproved(user?._id)}>
                                                <FaCheck /> Approve
                                            </button>
                                            <button class="btn btn-outline-danger gap-2  mx-3 d-flex align-items-center" type="button" onClick={() => handleDenyRefferer(user?._id)}>
                                                <MdOutlineCancel /> Deny
                                            </button>
                                            </div>

                                            }
                                            {
                                            (user.is_account_suspended ?? false) ? (
                                                <button className="btn btn-outline-success  d-flex align-items-center" type="button" onClick={() => handleAccountReinstate(user?._id)}>
                                                Reinstate
                                                </button>
                                            ) : (
                                                <button className="btn btn-outline-warning mx-3 d-flex align-items-center" type="button" onClick={() =>handleAccountSuspend(user?._id)}>
                                                <LuPauseOctagon /> Suspend
                                                </button>
                                            )
                                            }

                                        </td>

                                    </tr>
                                )}


                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            {userList?.length > 10 &&
                <ReactPaginate
                    breakLabel="..."
                    breakClassName={'break'}
                    nextLabel="next >"
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    onPageChange={handlePageClick}
                    forcePage={page}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="< previous"

                />
            }
        </div>

    )
}

export default Users