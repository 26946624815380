import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useGetUserDetailQuery, useEditUserMutation } from "../../services/UserApi"
//import LoadingAction from "../../../../components/Loader";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { getBaseUrl, GetUrl } from "../../config/GetUrl";
import ListingPage from "./listingPage";
import ListingPageRefferer from "./ListingPageReffere";
import ListingPageClient from "./listingPage";
import { useGetSubscriptionsByIdQuery, useGetTransactionsByIdQuery } from "../../services/UserManageApi";

const buttonWidth = {
    width: "100%",
};

const UserView = (props) => {
    const { showView, handleClose, userID } = props;
    const [editUser] = useEditUserMutation();
    const { data, isLoading, isError } = useGetUserDetailQuery(userID);
    const {data:transData , isError:isTransError} = useGetTransactionsByIdQuery(userID);
    const [categoryName, setCategoryName] = useState("");
    const [userDatail, setUserDetail] = useState('')
    const[transactions , setTransactions] = useState(null);
    const [subscriptions, setSubscriptions] = useState(null);

    const {data:subscriptionsData , isError:isSubscriptionsError} = useGetSubscriptionsByIdQuery(
        userID,
    );
    console.log('subscriptionsData--->',subscriptionsData)

    useEffect(() => {
        setSubscriptions(subscriptionsData?.data?.subscriptions)
    } , [subscriptionsData])


    useEffect(() => {
        setCategoryName(data?.data?.user?.name)
        setUserDetail(data?.data?.user)
    }, [data, userID])

    const handleCategoryNameChange = (e) => {
        setCategoryName(e.target.value);
    };

    useEffect(() => {
        if(userDatail?.user?.role === '	Referee'){
          console.log('setting fetchSubs to true');
        }
    } , [userDatail])

    useEffect(() => {
        console.log('transData--->',transData)
        const billingData = transData?.data?.map((item) => ({
            amount: item.amount,
            billing_date:item.createdAt,
            plan:item.plan_id.plan_name,
            type:item.plan_id.plan_type,
            name:item.plan_creator_id.name
        }) )
        setTransactions(billingData);
    } , [transData])

    useEffect(() => {
        console.log('transactions--->',transactions)
    } , [transactions])

    useEffect(() => {
        console.log('userDatail--->',userDatail?.user)
        console.log(`${getBaseUrl.BASE_URL}${userDatail?.buisnessProfile?.logo}`)
        console.log(`${getBaseUrl.BASE_URL}${userDatail?.buisnessProfile?.banner[0]}`)
    } , [userDatail])



    const handleSubmit = (e) => {
        handleClose();
    };

    const formattedDate = (date) => {
        const options = {
          weekday: 'long',   // e.g., "Friday"
          year: 'numeric',   // e.g., "2024"
          month: 'long',     // e.g., "August"
          day: 'numeric',    // e.g., "2"
        };
        return new Intl.DateTimeFormat('en-US', options).format(date);
      };
      
      // Example usage:
      const date = new Date("2024-08-02T10:42:07.274Z");
      console.log(formattedDate(date)); // Outputs: "Friday, August 2, 2024"
      

    return (
        <Modal show={showView} onHide={handleClose} centered size="lg">
            <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
                <div className="d-flex justify-content-center w-100">
                    <Modal.Title className="h5">View User</Modal.Title>
                </div>
            </Modal.Header>
            {/* {showLoader && <LoadingAction />} */}
            <Modal.Body className="p-4">
                <Tabs
                    defaultActiveKey="profile"
                    id="uncontrolled-tab-example"
                    className="mb-3 justify-content-center nav-justified mb-4 modal-Tabs"
                >
                    <Tab eventKey="home" title="User Details" className="">


                        <div className="mb-4">
                            <label className="form-label mb-0">User Profile</label>
                            <div className="mb-4 d-flex justify-content-center align-items-center">
                                <img 
                                    src={`${getBaseUrl.BASE_URL}${userDatail?.user?.buisnessProfile?.logo}`} 
                                    className="img-thumbnail rounded-circle" 
                                    alt="user-profile" 
                                    style={{ 
                                        height: '200px', // Fixed height
                                        width: 'auto',   // Proportional width
                                        objectFit: 'cover' // Ensures the image is proportionally cropped
                                    }} 
                                />
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-4">
                                    <label className="form-label mb-0">User Name</label>
                                    <p className="h5 fw-bolder">{userDatail?.user?.name}</p>
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="mb-4">
                                    <label className="form-label mb-0">User Email</label>
                                    <p className="h5 fw-bolder">{userDatail?.user?.email}</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="mb-4">
                                <label className="form-label mb-0">User Phone Number</label>
                                <p className="h5 fw-bolder">{userDatail?.user?.buisnessProfile?.phone}</p>
                            </div>
                        </div>



                    </Tab>
                    <Tab eventKey="profile" title="Listing Page">
                        {userDatail?.user?.role === 'Client' && <ListingPageClient userDetail={userDatail}/>}
                        {userDatail?.user?.role === 'Referrer' && <ListingPageRefferer userDetail={userDatail}/>}
                        {/* <div className="mb-4">
                            <label className="form-label mb-0">User Profile</label>
                            <div className="mb-4 d-flex justify-content-center align-items-center">
                                <img 
                                    src={`${getBaseUrl.BASE_URL}${userDatail?.buisnessProfile ? userDatail.buisnessProfile.banner[0] : ''}`} 
                                    className="img-thumbnail" 
                                    alt="banner-img" 
                                    style={{ 
                                        width: '50%',   // Half the parent's width
                                        height: '200px', // Fixed height
                                        objectFit: 'cover' // Maintain aspect ratio without distortion
                                    }} 
                                />
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-4">
                                    <label className="form-label mb-0">User Name</label>
                                    <p className="h5 fw-bolder">{userDatail?.buisnessProfile?.name}</p>
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Address</label>
                                    <p className="h5 fw-bolder">{userDatail?.buisnessProfile?.address}</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                            <div className="mb-4">
                                <label className="form-label mb-0">Phone Number</label>
                                <p className="h5 fw-bolder">{userDatail?.buisnessProfile?.phone}</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-4">
                                <label className="form-label mb-0">State</label>
                                <p className="h5 fw-bolder">{userDatail?.buisnessProfile?.state}</p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-4">
                                <label className="form-label mb-0">Descriptions</label>
                                <p className="h5 fw-bolder">{userDatail?.buisnessProfile?.description}</p>
                            </div>
                        </div>
                        </div>

                       */}

                    </Tab>
                    {(userDatail?.user?.role === "Referrer" || userDatail?.user?.role === "Referee") && (
                        <Tab eventKey="associated clients" title="Associated Clients">
                            <div className="table-responsive">
                            <table className="table table-striped table-bordered">
                                <thead className="table-dark">
                                <tr>
                                    <th scope="col">Sr. No.</th>
                                    <th scope="col">Plan Name</th>
                                    <th scope="col">Associated Client</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Expired On</th>
                                </tr>
                                </thead>
                                <tbody>
                                {subscriptions && subscriptions.length > 0 ? (
                                    subscriptions.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item?.plan_id?.plan_name || 'N/A'}</td>
                                        <td>{item?.user_id?.buisnessProfile?.name || 'N/A'}</td>
                                        <td>{item.status || 'N/A'}</td>
                                        <td>{formattedDate(new Date(item.expiresAt)) || 'N/A'}</td>
                                    </tr>
                                    ))
                                ) : (
                                    <tr>
                                    <td colSpan="5" className="text-center">
                                        No Subscriptions Found
                                    </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                            </div>
                        </Tab>
                        )}

                    <Tab eventKey="billing_dates" title="billing dates">
                    <div>
                        {transactions?.length > 0 ? (
                            <table className="table table-bordered table-striped table-hover">
                            <thead className="thead-dark">
                                <tr>
                                <th>Sr. No</th>
                                <th>Billing Date</th>
                                <th>Amount</th>
                                <th>Plan</th>
                                <th>plan type</th>
                                <th>Refferer</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transactions.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{formattedDate(new Date(item.billing_date))}</td>
                                    <td>{item.amount}</td>
                                    <td>{`${item.plan}`}</td>
                                    <td>{item.type}</td>
                                    <td>{item.name}</td>
                                </tr>
                                ))}
                            </tbody>
                            </table>
                        ) : (
                            <p>No transactions available</p>
                        )}
                        </div>

                    </Tab>
                </Tabs>
                <form onSubmit={handleSubmit} className="form-login n">
                    <div className="text-center">
                        <button
                            type="submit"
                            className="btn btn-primary py-3 fw-bolder"
                            style={buttonWidth}
                        >
                            close
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};



export default UserView