import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import RegisterUserForm from "./addUserForms/addUserForm1";
import BusinessProfileDetails from "./addUserForms/addUserForm2";
import BusinessImagesForm from "./addUserForms/addUserForm3";
import { useDispatch } from "react-redux";

const AddUserModal = ({ show, handleClose , refetch}) => {
  const [currentPage, setCurrentPage] = useState(0);


  const pages = [
      <RegisterUserForm setCurrentPage={setCurrentPage} />,
      <BusinessProfileDetails setCurrentPage={setCurrentPage}/>,
      <BusinessImagesForm setCurrentPage={setCurrentPage} handleClose={handleClose} refetch={refetch}/>,
  ];

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add New User</Modal.Title>
      </Modal.Header>
      <Modal.Body>{pages[currentPage]}</Modal.Body>
    </Modal>
  );
};

export default AddUserModal;
